import { Spin } from 'antd';
import React from 'react';
import { WrapContent } from '../../../utility/styledComponent/shared/WrapContent';

function Loading() {
    return (
        <>
            <WrapContent
                width="100%"
                height="100%"
                zIndex="1"
                position='absolute'
                top='0'
                backgroundColor='#ffffff66'
            >
                <WrapContent
                    width="100%"
                    height="100vh"
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Spin size="large" />
                </WrapContent>
            </WrapContent>
        </>
    );
}

export default Loading;
