// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./fonts/FontsFree-Net-SFProDisplay-Medium.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n\tfont-family: \"SFPro\";\n\tsrc: local(\"SFPro\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n\nbody {\n\tmargin: 0;\n\tfont-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\",\n\t\t\"Droid Sans\", \"Helvetica Neue\", sans-serif;\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n\tfont-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\n}\n\n.ant-picker-content .ant-picker-time-panel-column::after {\n\theight: 0;\n}\n\n.ant-picker-dropdown .ant-picker-panel-container .ant-picker-footer button.ant-btn.ant-btn-primary {\n\tbackground-color: #59c3b8;\n\tborder-color: #59c3b8;\n}\n", "",{"version":3,"sources":["webpack://src/index.css"],"names":[],"mappings":"AAAA;CACC,oBAAoB;CACpB,+EAA0F;AAC3F;;AAEA;CACC,SAAS;CACT;4CAC2C;CAC3C,mCAAmC;CACnC,kCAAkC;AACnC;;AAEA;CACC,+EAA+E;AAChF;;AAEA;CACC,SAAS;AACV;;AAEA;CACC,yBAAyB;CACzB,qBAAqB;AACtB","sourcesContent":["@font-face {\n\tfont-family: \"SFPro\";\n\tsrc: local(\"SFPro\"), url(./fonts/FontsFree-Net-SFProDisplay-Medium.ttf) format(\"truetype\");\n}\n\nbody {\n\tmargin: 0;\n\tfont-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\",\n\t\t\"Droid Sans\", \"Helvetica Neue\", sans-serif;\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n\tfont-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\n}\n\n.ant-picker-content .ant-picker-time-panel-column::after {\n\theight: 0;\n}\n\n.ant-picker-dropdown .ant-picker-panel-container .ant-picker-footer button.ant-btn.ant-btn-primary {\n\tbackground-color: #59c3b8;\n\tborder-color: #59c3b8;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
