const tokenKey = "ACCESS_TOKEN";
const refreshTokenKey = "REFRESH_TOKEN";

const sessionToken: sessionTokenInterface = {
	getToken() {
		return window.localStorage.getItem(tokenKey) || "";
	},
	setToken(token) {
		window.localStorage.setItem(tokenKey, token);
	},
	clearToken() {
		window.localStorage.removeItem(tokenKey);
	},
	getRefreshToken() {
		return window.localStorage.getItem(refreshTokenKey) || "";
	},
	setRefreshToken(token) {
		window.localStorage.setItem(refreshTokenKey, token);
	},
	clearRefreshToken() {
		window.localStorage.removeItem(refreshTokenKey);
	}
};

interface sessionTokenInterface {
	getToken(): string;
	setToken(token: string): void;
	clearToken(): void;
	getRefreshToken(): string;
	setRefreshToken(token: string): void;
	clearRefreshToken(): void;
}

export { sessionToken };
