import { AxiosRequestConfig } from "axios";
import { axiosInstance, axiosInstanceAuth } from "../../../configs/axiosConfig";
import { ILoginEffect, IResetPasswordEffect, IResetTokenEffect } from "./interface";
import { sessionToken } from "../../../authServices/jwt";

const configs: AxiosRequestConfig = {
	headers: {
		"Content-Type": "application/json",
	},
};

export const getLoginEffect = async (data: ILoginEffect) => {
	try {
		return await axiosInstanceAuth.post("/newAuth", {
			username: data.username,
			password: data.password,
			role: "SC"
		}, configs);
	} catch (error) {
		return error;
	}
};

export const getResetTokenEffect = async (data: IResetTokenEffect) => {
	try {
		return await axiosInstance.post("/auth/forgot-password/send-code", data, configs);
	} catch (error) {
		return error;
	}
};

export const resetPasswordEffect = async (data: IResetPasswordEffect) => {
	try {
		return await axiosInstance.post("/auth/forgot-password/new-password", data, configs);
	} catch (error) {
		return error;
	}
};

export const getLogoutEffect = async () => {
	try {
		return await axiosInstance.post("/auth/logout", {}, configs);
	} catch (error) {
		return error;
	}
};

export const refreshTokenEffect = async () => {
	try {
		const refreshToken = sessionToken.getRefreshToken();
		return await axiosInstanceAuth.post("/newAuth", {
			refreshToken,
			role: "SC"
		}, configs);
	} catch (error) {
		return error;
	}
};
export const getResetPasswordCodeEffect = async (email: string) => {
    try {
        return await axiosInstance.post("/auth/forgot-password/send-code", {email});
    } catch (error) {
        return error;
    }
};

export const confirmResetPasswordCodeEffect = async (token: string) => {
    try {
        return await axiosInstance.post("/auth/forgot-password/confirm-code", {token});
    } catch (error) {
        return error;
    }
};
