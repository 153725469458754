import styled from "styled-components";
import { Layout } from "antd";

export const HeaderContainer = styled(Layout.Header)`
	z-index: 19;
	background: #59c3b8;
	padding: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 48px;
	position: relative;
	box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

	& .logo {
		img {
			height: 36px;
			//width: 100%;
			padding: 5px;
			margin-left: 24px;
		}
	}

	& .input-search {
		display: inline-block;
		width: 200px;
		margin: 16px;
	}

	& .icon {
		display: inline-block;
		font-size: 18px;
		line-height: 48px;
		padding: 0 15px;
		cursor: pointer;
		transition: color 0.3s;

		&:hover {
			color: #1890ff;
		}
	}

	& .notification-icon {
		line-height: 40px;
		padding: 0 5px;
	}

	& .logout-button {
		margin-right: 10px;
	}
`;
