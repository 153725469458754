import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { rgba } from "../../components/interface/appInterface";
import { invalidTimeOffset } from "../../constant/variables";

const capitalizing = (str: string): string => {
	if (!str) {
		return str;
	}
	return str
		.split("-")
		.map((element) => element[0].toUpperCase().concat(element.slice(1).toLowerCase()))
		.join(" ");
};

const capitalizingSentence = (str: string): string => {
	if (!str) {
		return str;
	}
	return str
		.split(" ")
		.map((element) => element[0].toUpperCase().concat(element.slice(1).toLowerCase()))
		.join(" ");
};

const lowercase = (str: string): string => {
	return str
		.split(" ")
		.map((element) => element.toLowerCase())
		.join("-");
};

const showDeleteConfirm = (content: string, metaData: any, callback: any) => {
	Modal.confirm({
		title: "Are you sure delete row?",
		icon: <ExclamationCircleOutlined />,
		content: content,
		okText: "Yes",
		okType: "danger",
		cancelText: "No",
		onOk: () => callback(true, metaData),
		onCancel: () => callback(false, metaData),
	});
};

const toDate = (num: number | undefined): string | null => {
	if (!num) {
		return null;
	}
	const date = new Date(num * 1000);
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();
	return `${day >= 10 ? day : `0${day}`}/${month >= 10 ? month : `0${month}`}/${year}`;
};

const convertDate = (date: Date, locale: string) => {
	if (!date) return "-";
	return date
		.toLocaleDateString(locale || "vi", { year: "numeric", month: "2-digit", day: "2-digit" })
		.replaceAll("/", "-")
		.replaceAll(". ", "-")
		.replaceAll(".", "");
};

const toCss = (color: rgba | undefined): string => {
	if (!color) return "";
	return `rgba(${color?.r},${color?.g},${color?.b},${color?.a})`;
};

const timeStampToTimeString = (timeInput: number | string): string => {
	const time = Number(timeInput) * 60;
	const hour = Math.floor(time / 3600);
	const minute = Math.floor((time - hour * 3600) / 60);
	const second = (time - hour * 3600) % 60;
	return `${hour >= 10 ? hour : `0${hour}`}:${minute >= 10 ? minute : `0${minute}`}:${second}`;
};

const timeStampToTimeStringWithouMinute = (timeInput: number): string => {
	const time = timeInput * 60;
	const hour = Math.floor(time / 3600);
	const minute = Math.floor((time - hour * 3600) / 60);
	return `${hour >= 10 ? hour : `0${hour}`}:${minute >= 10 ? minute : `0${minute}`}`;
};

const timeStringToTimeStamp = (string: string): number => {
	const time = string.split(":").map((item) => Number(item) || 0);
	return time[0] * 60 + time[1];
};

const getTimezoneOffset = (d: any, tz: any) => {
	try {
		const a = d.toLocaleString("ja", { timeZone: tz }).split(/[/\s:]/);
		a[1]--;
		const t1 = Date.UTC.apply(null, a);
		const t2 = new Date(d).setMilliseconds(0);
		return (t2 - t1) / 60 / 1000;
	} catch (error) {
		return invalidTimeOffset;
	}
};

export {
	capitalizing,
	showDeleteConfirm,
	lowercase,
	capitalizingSentence,
	toDate,
	convertDate,
	toCss,
	timeStampToTimeString,
	timeStringToTimeStamp,
	timeStampToTimeStringWithouMinute,
	getTimezoneOffset,
};
