import React from "react";
import { HeaderContainer } from "./header.styles";
import logo from "../../../../assets/img/twc_logo.png";

function Header() {
	return (
		<HeaderContainer>
			<div className="logo">
				<img src={logo} alt="logo" />
			</div>
		</HeaderContainer>
	);
}

export default Header;
