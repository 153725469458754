import {
	GetAuthFailed,
	GetAuthPending,
	GetAuthSuccess,
	GetResetTokenPending,
	GetResetTokenSuccess,
	GetResetTokenFailed,
	GetLoginPending,
	GetLoginSuccess,
	GetLoginFailed,
	ResetPasswordPending,
	ResetPasswordSuccess,
	ResetPasswordFailed,
	GetLogoutPending,
	GetLogoutSuccess,
	GetLogoutFailed,
	RefreshTokenPending,
	RefreshTokenSuccess,
	RefreshTokenFailed,
    GetResetPasswordCodePending,
    GetResetPasswordCodeSuccess,
    GetResetPasswordCodeFailed,
    ConfirmResetPasswordCodePending,
    ConfirmResetPasswordCodeSuccess,
    ConfirmResetPasswordCodeFailed
} from './authActionType'

import { IActionType } from '../../../interface/commonInterface'

// Login
export function getLoginPending(): IActionType {
	return {
		type: GetLoginPending
	}
}

export function getLoginFailed(errorMsg: string): IActionType {
	return {
		type: GetLoginFailed,
		payload: errorMsg
	}
}

export function getLoginSuccess(user: any): IActionType {
	return {
		type: GetLoginSuccess,
		payload: user
	}
}

// Logout
export function getLogoutPending(): IActionType {
	return {
		type: GetLogoutPending
	}
}

export function getLogoutSuccess(): IActionType {
	return {
		type: GetLogoutSuccess
	}
}

export function getLogoutFailed(errorMsg: string): IActionType {
	return {
		type: GetLogoutFailed,
		payload: errorMsg
	}
}

// Authentication
export function getAuthPending(): IActionType {
	return {
		type: GetAuthPending
	}
}

export function getAuthFailed(errorMsg: string): IActionType {
	return {
		type: GetAuthFailed,
		payload: errorMsg
	}
}

export function getAuthSuccess(user: any): IActionType {
	return {
		type: GetAuthSuccess,
		payload: user
	}
}

// Request reset password token
export function getResetTokenPending(): IActionType {
	return {
		type: GetResetTokenPending
	}
}

export function getResetTokenSuccess(token: string): IActionType {
	return {
		type: GetResetTokenSuccess,
		payload: token
	}
}

export function getResetTokenFailed(errorMsg: string): IActionType {
	return {
		type: GetResetTokenFailed,
		payload: errorMsg
	}
}

// Reset password
export function resetPasswordPending(): IActionType {
	return {
		type: ResetPasswordPending
	}
}

export function resetPasswordSuccess(): IActionType {
	return {
		type: ResetPasswordSuccess
	}
}

export function resetPasswordFailed(errorMsg: string): IActionType {
	return {
		type: ResetPasswordFailed,
		payload: errorMsg
	}
}

// Refresh token
export function refreshTokenPending(): IActionType {
	return {
		type: RefreshTokenPending
	}
}

export function refreshTokenSuccess(token: string): IActionType {
	return {
		type: RefreshTokenSuccess,
		payload: token
	}
}

export function refreshTokenFailed(errorMsg: string): IActionType {
	return {
		type: RefreshTokenFailed,
		payload: errorMsg
	}
}
export function getResetPasswordCodePending(): IActionType {
    return {
        type: GetResetPasswordCodePending,
    };
}

export function getResetPasswordCodeSuccess(): IActionType {
    return {
        type: GetResetPasswordCodeSuccess,
    };
}

export function getResetPasswordCodeFailed(errorMsg: string): IActionType {
    return {
        type: GetResetPasswordCodeFailed,
        payload: errorMsg,
    };
}

export function confirmResetPasswordCodePending(): IActionType {
    return {
        type: ConfirmResetPasswordCodePending,
    };
}

export function confirmResetPasswordCodeSuccess(token: string): IActionType {
    return {
        type: ConfirmResetPasswordCodeSuccess,
        payload: token,
    };
}

export function confirmResetPasswordCodeFailed(errorMsg: string): IActionType {
    return {
        type: ConfirmResetPasswordCodeFailed,
        payload: errorMsg,
    };
}

