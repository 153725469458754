import React, { useState } from "react";
import { Menu, message } from "antd";
import { useHistory } from "react-router-dom";
import { SidebarContainer } from "./sidebar.styles";
import { categories } from "../../../../configs/data/categories";
import { capitalizing } from "../../../../utility/helpers";
import { getLogout } from "../../../../utility/store/middlerware/authMiddleware";
import { GetAuthStateContext } from "../../../../utility/context/authContext";
import Loading from "../../../../components/UI/Loading";

function Sidebar() {
	const [collapsed, setCollapsed] = useState(false);
	const history = useHistory();
	const { dispatch, authState } = GetAuthStateContext();
	const pathname = history.location.pathname.split("/").filter((item) => item !== "");
	const [openKey, setOpenKey] = useState([`${pathname[0]}`]);
	const [selectKey, setSelectKey] = useState([
		pathname[1] === "setting" ? pathname[0] + "/" + pathname[1] : pathname[0],
	]);
	const rootKey = categories.map((cate) => cate.key);

	const setDefaultSelectedKey = () => {
		const pathname = history.location.pathname.split("/").filter((item) => item !== "");
		setSelectKey([pathname[1] === "setting" ? pathname[0] + "/" + pathname[1] : pathname[0]]);
	};

	const onClickDashboard = (e: any) => {
		if (e.key === "logout") {
			getLogout(dispatch).then((res) => {
				if (res.result) {
					message.success(res.message);
					history.push("/auth");
				} else if (!res.result) {
					message.error(res.message);
				}
			});
			return;
		}
		history.push("/" + e.key);
		setDefaultSelectedKey();
	};

	const onOpenChange = (keys: any[]) => {
		const latestOpenKey = keys.find((key) => openKey.indexOf(key) === -1);
		if (latestOpenKey && rootKey.indexOf(latestOpenKey) === -1) {
			setOpenKey(keys);
		} else {
			setOpenKey(latestOpenKey ? [latestOpenKey] : []);
		}
	};

	const renderSidebar = () => {
		return categories.map((cate) => {
			const { key, icon, items } = cate;
			if (!items || items.length === 0) {
				return (
					<Menu.Item key={`${key}`} icon={icon}>
						{capitalizing(key || "")}
					</Menu.Item>
				);
			}
			const menuItem = items.map((item: any) => (
				<Menu.Item key={`${key}/${item.key}`}>{capitalizing(item.key)}</Menu.Item>
			));
			return (
				<Menu.SubMenu key={key} title={capitalizing(key || "")} icon={icon}>
					{menuItem}
				</Menu.SubMenu>
			);
		});
	};

	return (
		<>
			<SidebarContainer
				className="sidebar menu-custom"
				collapsible
				collapsed={collapsed}
				breakpoint="lg"
				onCollapse={() => {
					setCollapsed((prevState) => !prevState);
				}}
				// width='fit-content'
				width="240px"
				theme="light"
			>
				<Menu
					theme="light"
					defaultSelectedKeys={[pathname[1] ? pathname[0] + "/" + pathname[1] : pathname[0]]}
					selectedKeys={selectKey}
					openKeys={openKey}
					onOpenChange={onOpenChange}
					mode="inline"
					onClick={onClickDashboard}
				>
					{renderSidebar()}
				</Menu>
			</SidebarContainer>
			{authState.pending && <Loading />}
		</>
	);
}

export default Sidebar;
