import styled from "styled-components";

export const OverrideCss = styled.div`
	font-family: SFPro;
	.ant-radio.ant-radio-checked {
		.ant-radio-inner {
			border-color: #59c3b8;
			&:after {
				background-color: #59c3b8;
				top: 1px;
				left: 1px;
				width: 12px;
				height: 12px;
			}
		}
	}
	.ant-switch {
		.ant-switch-handle {
			&:before {
				box-shadow: 0 2px 0px 0 rgb(0 35 11 / 20%);
			}
		}
	}
	.ant-switch.ant-switch-checked {
		background-color: #59c3b8;
	}
	.btn-custom {
		.ant-btn.ant-btn-primary {
			background-color: #59c3b8;
			border-color: #59c3b8;
		}
		.ant-btn.ant-btn-primary.ant-btn-dangerous {
			background-color: #ad1616;
			border-color: #ad1616;
		}
	}

	.link-custom {
		a {
			color: #59c3b8;
		}
	}
	.menu-custom {
		.ant-menu-submenu > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
			display: none;
		}
		.ant-menu-item.ant-menu-item-selected {
			background-color: white;
			color: #59c3b8;
			font-weight: bold;
		}
		.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-selected {
			color: #59c3b8;
			font-weight: bold;
			.ant-menu-item {
				font-weight: normal;
			}
			.ant-menu-item.ant-menu-item-selected {
				/* font-weight: bold; */
				color: black;
				:before {
					content: "";
					width: 5px;
					height: 15px;
					background-color: #59c3b8;
					border-radius: 7px;
					position: absolute;
					top: 14px;
					left: 40px;
				}
			}
		}
		.ant-menu-vertical .ant-menu-item,
		.ant-menu-vertical-left .ant-menu-item,
		.ant-menu-vertical-right .ant-menu-item,
		.ant-menu-submenu.ant-menu-submenu-inline,
		.ant-menu-submenu-title,
		.ant-menu-inline .ant-menu-item {
			:after {
				border: none;
			}
			:hover {
				color: #59c3b8;
			}
		}
	}

	.title-card-custom {
		font-size: 16px;
		font-weight: bold;
		margin-left: 45px;
		margin-bottom: 20px;
	}

	.btn-save-footer {
		background-color: #59c3b8;
		border-color: #59c3b8;
	}
	.btn-cancel-footer {
		background-color: #707070;
		border-color: #707070;
		color: white;
		margin: 0 19px 0 33px;
	}

	.search-none-icon {
		.ant-input-group-addon {
			display: none;
		}
	}

	.ant-form {
		.ant-form-item-required::before {
			color: black !important;
		}
	}
`;
