import React, {lazy} from 'react';
import {Result} from "antd";

const PendingLayout = lazy(() => import('../../../layouts/Pending'))

function NoPermission() {
	return (
			<PendingLayout>
				<Result
						status="403"
						title="401"
						subTitle="Sorry, you are not allowed to get in."
				/>
			</PendingLayout>
	);
}

export default NoPermission;
