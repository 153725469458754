import React, { lazy, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AntSuspense from "./components/UI/AntSuspense";
import { sessionToken } from "./authServices/jwt";
import { GetAuthStateContext } from "./utility/context/authContext";
import { refreshToken } from "./utility/store/middlerware/authMiddleware";
import DashboardLayout from "./layouts/Dashboard";
import DashboardAppConfig from './layouts/DashboardAppConfig';
import Loading from "./components/UI/Loading";
import { Role } from "./constant/role";
import NoPermission from "./views/pages/NoPermission";
import { message } from "antd";

const Home = lazy(() => import("./views/pages/Home"));
const Auth = lazy(() => import("./views/pages/Auth"));
const NotFound = lazy(() => import("./views/pages/NotFound"));
const Account = lazy(() => import("./views/pages/Account"));
const Retailer = lazy(() => import("./views/pages/Retailer"));
const EmailTemplate = lazy(() => import("./views/pages/EmailTemplate"));
const AppConfig = lazy(() => import("./views/pages/AppConfig"));
const SendGridConfig = lazy(() => import("./views/pages/SendGridConfig"));
const PlansPrice = lazy(() => import("./views/pages/PlansPrice"));

function AppRouter() {
	const _sessionToken = sessionToken.getToken();
	const { authState, dispatch } = GetAuthStateContext();
	const [loading, setLoading] = useState<boolean | undefined>(true);

	const checkAuth = (Component: JSX.Element): JSX.Element => {
		if (!authState.accessToken && !sessionToken.getToken()) {
			return <Redirect to="/auth" />;
		}
		if (authState.auth?.realm_access?.roles?.includes('twc-super-user')) {
			return (
				<DashboardLayout>
					<AntSuspense>{Component}</AntSuspense>
				</DashboardLayout>
			);
		}
		return <Redirect to="/no-permission" />;
	};

	const checkAuth_AppConfig = (Component: JSX.Element): JSX.Element => {
		if (!authState.accessToken && !sessionToken.getToken()) {
			return <Redirect to="/auth" />;
		}
		if (authState.auth?.realm_access?.roles?.includes('twc-super-user')) {
			return (
				<DashboardAppConfig>
					<AntSuspense>{Component}</AntSuspense>
				</DashboardAppConfig>
			);
		}
		return <Redirect to="/no-permission" />;
	};

	useEffect(() => {
		if (sessionToken.getToken() && !authState.accessToken) {
			refreshToken(dispatch).then((res) => {
				if (res.result) {
					setLoading(false);
				}
			});
		} else if (!sessionToken.getToken() && !authState.accessToken) {
			setLoading(false);
		}
		
		setInterval(() => {
			refreshToken(dispatch)
				.then((res) => res)
				.catch((error) => error);
		}, 5 * 60 * 1000);
		// eslint-disable-next-line
	}, []);

	if (loading || authState.pending) {
		return <Loading />;
	}

	return (
		<BrowserRouter>
			<Switch>
				<Route
					path="/auth"
					render={() => {
						if (!_sessionToken && !authState.accessToken) {
							return (
								<AntSuspense>
									<Auth />
								</AntSuspense>
							);
						}
						return <Redirect to="/" />;
					}}
				/>
				<Route
					exact
					path="/"
					render={() => {
						return checkAuth(<Home />);
					}}
				/>
				<Route
					path="/setup-email-template"
					render={() => {
						return checkAuth(<EmailTemplate />);
					}}
				/>
				<Route
					path="/staff-management"
					render={() => {
						return checkAuth(<Account />);
					}}
				/>
				<Route
					path="/retailers-management"
					render={() => {
						return checkAuth(<Retailer />);
					}}
				/>
				<Route
					path="/app-configuration/:shopHash"
					render={() => {
						return checkAuth_AppConfig(<AppConfig />);
					}}
				/>
				<Route
					path = '/sendgrid-config'
					render={() => {
						return checkAuth(<SendGridConfig />);
					}}
				/>
				<Route
					path = '/plans-price'
					render={() => {
						return checkAuth(<PlansPrice />);
					}}
				/>
				<Route
					exact
					path="/404"
					render={() => {
						return checkAuth(<NotFound />);
					}}
				/>
				<Route
					exact
					path="/no-permission"
					render={() => {
						if (
							authState.auth?.roles?.some(
								(item: any) => item?.id === Role.admin || item?.id === Role.staff
							)
						) {
							return <Redirect to="/" />;
						}
						return (
							<AntSuspense>
								<NoPermission />
							</AntSuspense>
						);
					}}
				/>
				<Redirect to="/404" />
			</Switch>
		</BrowserRouter>
	);
}

export default AppRouter;
