import styled from "styled-components";

export const HomeContainer = styled.div`
	& .layout {
		min-height: 100vh;
	}

	& .sidebar {
		min-height: 100%;
	}
`;
