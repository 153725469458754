import React from 'react';
import { Button, Col, Layout, Row } from 'antd'
import { ContainerConfigStoreOwner } from "./index.styles";
import { WrapContent } from "../../utility/styledComponent/shared/WrapContent";
import Sidebar from "./partial/Sidebar";
import { useHistory, useParams } from 'react-router-dom';
import {
    ArrowLeftOutlined
} from '@ant-design/icons';

const DashboardLayout: React.FC = (props) => {
    const { shopHash }: { shopHash: string } = useParams();
    const history = useHistory();
    return (
        <>
            <ContainerConfigStoreOwner>
                <Layout className="layout">
                    {/* <Header /> */}
                    <Row>
                        <Col span={24} className="header-config-app">
                            <div className="ctn-btn-back">
                                <Button
                                    icon={<ArrowLeftOutlined />}
                                    onClick={() => history.push(`/retailers-management/detail/${shopHash}`)}
                                >
                                    Back
                                </Button>
                            </div>
                            <p className="notification-config-shop">You are configuring as {shopHash}</p>
                        </Col>
                    </Row>
                    <Layout>
                        <div className='sidebar'>
                            <Sidebar />
                        </div>
                        <Layout.Content className="layout-content site-layout-background">
                            <WrapContent position="relative" height="100%">
                                {props.children}
                            </WrapContent>
                        </Layout.Content>
                    </Layout>
                </Layout>
            </ContainerConfigStoreOwner>
        </>
    );
}

export default DashboardLayout;
