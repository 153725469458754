import React from "react";
import AppRouter from "./Router";
import "antd/dist/antd.css";
import { OverrideCss } from "./utility/styledComponent/shared/Override";
import { AuthStateProvider } from "./utility/context/authContext";

function App() {
	return (
		<AuthStateProvider>
			<OverrideCss className="override">
				<AppRouter />
			</OverrideCss>
		</AuthStateProvider>
	);
}

export default App;
