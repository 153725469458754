import React from 'react';
import {Layout} from 'antd'

import {HomeContainer} from "./dashboard.styles";
import {WrapContent} from "../../utility/styledComponent/shared/WrapContent";
import Header from "./partial/Header";
import Sidebar from "./partial/Sidebar";

const DashboardLayout: React.FC = (props) => {
	return (
			<>
				<HomeContainer>
					<Layout className="layout">
						<Header/>
						<Layout>
							<div className='sidebar'>
								<Sidebar/>
							</div>
							<Layout.Content className="layout-content site-layout-background">
								<WrapContent position="relative" height="100%">
									{props.children}
								</WrapContent>
							</Layout.Content>
						</Layout>
					</Layout>
				</HomeContainer>
			</>


	);
}

export default DashboardLayout;
