import {
	GetAuthPending,
	GetAuthSuccess,
	GetAuthFailed,
	GetLoginPending,
	GetLoginFailed,
	GetLoginSuccess,
	GetResetTokenPending,
	GetResetTokenFailed,
	GetResetTokenSuccess,
	ResetPasswordPending,
	ResetPasswordSuccess,
	ResetPasswordFailed,
	GetLogoutPending,
	GetLogoutSuccess,
	GetLogoutFailed,
	RefreshTokenPending,
	RefreshTokenSuccess,
	RefreshTokenFailed,
	GetResetPasswordCodePending,
    GetResetPasswordCodeSuccess,
    GetResetPasswordCodeFailed,
    ConfirmResetPasswordCodePending,
    ConfirmResetPasswordCodeSuccess,
    ConfirmResetPasswordCodeFailed,
} from '../action/authAction/authActionType'
import { IStateInitialAuth } from '../../interface/stateInitial'
import { IActionType } from '../../interface/commonInterface'

export const initAuthState: IStateInitialAuth = {
	pending: false,
	refreshToken: '',
	accessToken: '',
	auth: {},
	errorMsg: '',
	resetToken: ''
}

export function authReducer(state = initAuthState, action: IActionType): IStateInitialAuth {
	const { type, payload } = action
	switch (type) {
		// Authentication
		case GetAuthPending:
			return {
				...state,
				pending: true
			}
		case GetAuthFailed:
			return {
				...state,
				pending: false,
				errorMsg: payload
			}
		case GetAuthSuccess:
			return {
				...state,
				errorMsg: '',
				pending: false,
				auth: payload
			}
		case GetLoginPending:
			return {
				...state,
				pending: true
			}
		case GetLoginSuccess:
			return {
				...state,
				errorMsg: '',
				pending: false,
				accessToken: payload
			}
		case GetLoginFailed:
			return {
				...state,
				pending: false,
				errorMsg: payload
			}
		case GetLogoutPending:
			return {
				...state,
				pending: true
			}
		case GetLogoutSuccess:
			return {
				pending: false,
				errorMsg: '',
				refreshToken: '',
				accessToken: '',
				resetToken: '',
				auth: {}
			}
		case GetLogoutFailed:
			return {
				...state,
				pending: false,
				errorMsg: payload
			}
		case GetResetTokenPending:
			return {
				...state,
				pending: true
			}
		case GetResetTokenSuccess:
			return {
				...state,
				errorMsg: '',
				pending: false,
				resetToken: payload
			}
		case GetResetTokenFailed:
			return {
				...state,
				pending: false,
				errorMsg: payload
			}
		case ResetPasswordPending:
			return {
				...state,
				pending: true
			}
		case ResetPasswordSuccess:
			return {
				...state,
				errorMsg: '',
				pending: false
			}
		case ResetPasswordFailed:
			return {
				...state,
				pending: false,
				errorMsg: payload
			}
		case RefreshTokenPending:
			return state
		case RefreshTokenSuccess:
			return {
				...state,
				errorMsg: '',
				accessToken: payload
			}
		case RefreshTokenFailed:
			return {
				...state,
				accessToken: '',
				errorMsg: payload
			}
		case GetResetPasswordCodePending:
			return {
				...state,
				pending: true
			}
		case GetResetPasswordCodeSuccess:
			return {
				...state,
				errorMsg: '',
				pending: false
			}
		case GetResetPasswordCodeFailed:
			return {
				...state,
				errorMsg: payload,
				pending: false
			}

		case ConfirmResetPasswordCodePending:
			return {
				...state,
				pending: true
			}
		case ConfirmResetPasswordCodeSuccess:
			return {
				...state,
				errorMsg: '',
				pending: false,
				resetToken: payload
			}
		case ConfirmResetPasswordCodeFailed:
			return {
				...state,
				pending: false,
				errorMsg: payload
			}
		default:
			return state
	}
}
