import styled from "styled-components";

export const ContainerConfigStoreOwner = styled.div`
	& .layout {
		min-height: 100vh;
	}

	& .sidebar {
		min-height: 100%;
	}

	.cnt-btn-back{
		position: absolute;

	}
	.header-config-app{
		position:relative;
		.ctn-btn-back{
			position: absolute;
			top: 0;
			margin-left: 24px;
			left: 0;
			height: 100%;
			display: flex;
			align-items: center;
			button{
				background-color:transparent;
				border-color:white;
				border-width:2px;
				color:white;
			}
		}

		.notification-config-shop{
			display: flex;
			margin:0;
			padding:10px 0;
			justify-content:center;
			align-items: center;
			background-color:#ea9290;
			font-size:20px;
			color:white;
		}
	}

	
`;
